@import '../../assets/scss/color.scss';

.lint-custom-dropdown {
  .lint-custom-dropdown-toggle.btn-primary.dropdown-toggle {
    width: 111px;
    height: 30px;
    border: 1px solid #6c757d;
    color: #212529 !important;
    font-size: 14px;
    border-radius: 6px;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    &:active,
    &:focus-visible {
      background-color: transparent;
      border-color: #6c757d;
    }

    &:after {
      display: none;
    }

    span {
      left: 92px;
      bottom: 5px;
    }

    &:disabled {
      background-color: #e9ecef !important;
      opacity: 1;
      cursor: default !important;
    }
  }

  .dropdown-menu {
    min-width: 75px !important;
    max-height: 300px;
    overflow: auto;
  }

  .dropdown-item:active,
  .dropdown-item:active,
  .dropdown-item:hover {
    background-color: $color_ultramarine !important;
    color: #ffffff !important;
  }
}

.lint-custom-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.dropdown-icon {
  position: absolute;
  right: 12px;
  top: 12px;
}

.lint-customize-dropdown {
  .lint-custom-dropdown-toggle.btn-primary.dropdown-toggle {
    width: 100%;
    height: 30px;
    border: 1px solid #6c757d;
    color: #212529 !important;
    font-size: 14px;
    border-radius: 6px;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    &:active,
    &:focus-visible {
      background-color: transparent;
      border-color: #6c757d;
    }

    &:after {
      display: none;
    }

    span {
      left: 92px;
      bottom: 5px;
    }

    &:disabled {
      background-color: #e9ecef !important;
      opacity: 1;
      cursor: default !important;
    }
  }

  .dropdown-menu.show {
    width: 100%;
    max-height: 300px;
    overflow: auto;
  }

  .dropdown-item:active,
  .dropdown-item:active,
  .dropdown-item:hover {
    background-color: $color_ultramarine !important;
    color: #ffffff !important;
  }
}

.accordion-collapse .interactive-list-dropdown,
.accordion-collapse .interactive-list-dropdown:active {
  width: 100%;
  height: 34px;
  gap: 16px;
  background-color: $white;
  border: 1px solid $color-lti-frame;
  border-radius: 8px;
  color: $color_dorado;
  .dropdown-text {
    color: $color_dorado;
    font-family: Lexend;
    font-weight: 350;
    font-size: 14px;
  }
}

.accordion-collapse .interactive-list-dropdown {
  display: flex;
  align-items: center;
  svg {
    position: relative;
    right: 4px;
  }
  &:hover,
  &:focus {
    border: 1px solid $gray-light;
  }
}

.accordion-collapse .interactive-list-dropdown[aria-expanded='true'] {
  border: 1px solid $gray-light;
}

.accordion-collapse .interactive-list-dropdown-option {
  padding: 4px 8px;
  color: $color_dorado;
  font-weight: 350;
  border-radius: 4px;
  font-family: Lexend;
  &:hover {
    background-color: $dropdown-hover !important;
    background: transparent;
    color: $color_dorado;
  }
  .tooltip-container:hover,
  .interactive-dropdown-option-title:hover {
    background: transparent;
    color: $color_dorado;
  }
}

.accordion-collapse .interactive-list-dropdown-option.selected {
  background-color: $dropdown-hover;
  font-weight: 450;
  color: $color-nero;
}

div:has(> ul > li.interactive-list-dropdown-option) {
  padding: 4px 1px;
  border-radius: 8px;
  font-size: 14px !important;
  font-family: Lexend;
  font-weight: 350;
  width: 160px;
  box-shadow: 0px 4px 20px 0px #58585833;
  transform: translate(0px, 0px);
  border: 1px solid $color-lti-frame;
  color: #585858;
}

ul:has(> li.interactive-list-dropdown-option) {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.icon-items .dropdown-text {
  font-family: Lexend;
  font-size: 14px;
  color: $color_dorado;
}

div > ul {
  li.interactive-list-dropdown-option {
    &:hover {
      background-color: #f2f2f2;
      color: #585858;
      .tooltip-container {
        background-color: #f2f2f2;
        color: #1c1c1c;
      }
    }
  }
  li.interactive-list-dropdown-option.selected {
    background-color: #f2f2f2;
    color: #1c1c1c;
  }
}

button.interactive-list-dropdown {
  border: 1px solid #d8d8d8 !important;
  border-radius: 8px;
  .text-capitalize {
    font-size: 14px !important;
    font-family: Lexend;
    color: #585858;
    font-style: normal;
    font-weight: 350;
  }
}

button:has(> svg.filterIcon) {
  border: none !important;
  div {
    display: none !important;
  }
}
